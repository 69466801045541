import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import styles from './AttendanceMobileDetail.module.css';
import { Layout, Typography, Table, Form, Input, Modal } from 'antd';
import { AuthButton, AuthButtonAuthorities } from '../../core/CoreForm'
import { Header, ContentEx as Content, CommonMessage, FooterButtonArea, Footer } from '../../core/CorePageContents'
import * as Module from '../../../modules/partner/attendance-mobile/attendanceMobileModule';
import * as Model from '../../../modules/partner/attendance-mobile/attendanceMobileModel';
import { initMessages, setMessages, setModalMessages, message } from '../../../modules/core/commonMessageModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as CONSTANTS from '../../../constants/constants';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as Utils from '../../../utils/utils';
import moment from 'moment';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { initApiResult, storedApiResult, setApiResult } from '../../../modules/core/bffApiModule';
import { AbolishCompanyListButton } from '../../core/parts/AbolishCompanyListButton'
import { MergeCompanyAnnotations } from '../../core/parts/MergeCompanyAnnotations'
import { calcWorkTime } from '../../../utils/CalculateUtils'
import { storedLoginUserInfo } from '../../../modules/core/authModule';

interface titleInterface {
  title: string;
}

const { Title } = Typography;

export const AttendanceMobileDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDetailDataList);
  let criteria = useSelector(Module.storedCriteria);
  let total = useSelector(Module.storedDetailTotal);
  let primeCompanyId = useSelector(Module.storedPrimeCompanyId);
  let primeCompanyName = useSelector(Module.storedPrimeCompanyName);
  let projectId = useSelector(Module.storedProjectId);
  let projectName = useSelector(Module.storedProjectName);
  let belongCompanyId = useSelector(Module.storedBelongCompanyId);
  let belongCompanyName = useSelector(Module.storedBelongCompanyName);
  let firstPartnerCompanyId = useSelector(Module.storedFirstPartnerCompanyId);
  let modalVisibleState = useSelector(Module.storedModalVisibleState);
  let info = useSelector(Module.storedSelectedAttendanceInfo);
  let editAvailableFlag = useSelector(Module.storedEditAvailableFlag);
  let isLoading = useSelector(Module.storedLoading);
  let worktimeConfirmModalVisibleState = useSelector(Module.storedWorktimeConfirmModalVisibleState);
  let worktimeConfirmText = useSelector(Module.storedWorktimeConfirmText);
  let worktimeConfirmTextDate = useSelector(Module.storedWorktimeConfirmTextDate);
  let isContainInvalidInOutTime = useSelector(Module.storedIsContainInvalidInOutTime);
  let existNotSubmitted = useSelector(Module.storedExistNotSubmitted);
  let loginUserInfo = useSelector(storedLoginUserInfo);

  let apiResult = useSelector(storedApiResult);
  let showDetailDataIndex = useSelector(Module.storedShowDetailDataIndexState);

  let showDataStatus = "";
  let showDataName = "";
  let showDays = 0;
  let showTotalWorkingTime = "";
  let showDailyWorkStatusList: Array<Model.DailyWorkStatusRow> = [];


  let history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    callGetDetailDataListApi();
  },
    [criteria]
  );

  /**
   * 資格一覧の整形
   * @param row 
   */
  const qualificationNameList = (row: Model.WorkRecordDetailRow) => {

    if (!row || !row.qualificationList) {
      return <div></div>
    }
    if (row.qualificationList.length === 0) {
      return <div>なし</div> // 保持資格：なし
    }

    return (<div>{
      row.qualificationList.map((qualificationName: string, index) => 
        <div key={index} className={styles.contentLeft}>{qualificationName}</div>
      )
    }</div>);
  };

  /**
   * 一次協力会社名の一覧の整形
   * @param row 
   */
  const firstPartnerCompanyName = (row: Model.WorkRecordDetailRow) => {

    if (!row) {
      return <div></div>
    }

    let firstPartnerCompanyName=<div className={styles.contentLeft}>{row.firstPartnerCompanyName}</div>;

      return firstPartnerCompanyName
  };

  /**
   * 最上位次数の整形
   * @param row 
   */
  const topHierarchyLevel = (row: Model.WorkRecordDetailRow) => {

    if (!row) {
      return <div></div>
    }

    let topHierarchyLevel=<div className={styles.contentLeft}>{row.topHierarchyLevel}</div>;

    return topHierarchyLevel;
  };


  // 廃止企業表示ボタン出力
  const abolishCompanyButton = (row: Model.WorkRecordDetailRow) => {
    if (row == null) {
      return <></>
    }

    return <AbolishCompanyListButton
        historyId={row.workRecordSubmissionHistoryId}
        abolishCompanyFlag={row.abolishCompanyFlag}
      />
  }


  const abolishCompanyAnnotations = () => {
    const list: any[] = [dataList[showDetailDataIndex]]
    return <MergeCompanyAnnotations attendanceList={list} mergeCompanyAttendanceFlgName={"abolishCompanyFlag"} />
  }

  /** 未提出（差戻含む）かどうかチェック */
  const isNotSubmitted = (row: Model.WorkRecordDetailRow) => {
    return isStatusNotSubmitted(row) || isStatusRemand(row) || row.approvalStatus === "";
  }

  /** 承認済＊かどうかチェック */
  const isStatusForceApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
  }

  /** 確認済かどうかチェック */
  const isStatusConfirmed = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
  }

  /** 提出済かどうかチェック */
  const isStatusUnApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED;
  }

  /** 差戻かどうかチェック */
  const isStatusRemand = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
  }

  /** 未提出かどうかチェック */
  const isStatusNotSubmitted = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
  }

  /**
   * 月末の日付の取得
   * @param attendanceYearMonth 
   */
  const getLastDay = (attendanceYearMonth: string) => {
    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    return new Date(year, month, 0).getDate();
  }

  if (total >= 1) {
    if (isStatusForceApproved(dataList[showDetailDataIndex])) {
      showDataStatus = "承認済＊";
    } else {
      switch (dataList[showDetailDataIndex].approvalStatus) {
        case CONSTANTS.ATTENDANCE_STATUS_APPROVED:
          showDataStatus = "承認済";
          break;
        case CONSTANTS.ATTENDANCE_STATUS_CONFIRMED:
          showDataStatus = "確認済";
          break;
        case CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED:
          showDataStatus = "提出済";
          break;
        case CONSTANTS.ATTENDANCE_STATUS_REMAND:
          showDataStatus = "差戻";
          break;
        case CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED:
          showDataStatus = "未提出";
          break;
      }
    }

    showDataName = dataList[showDetailDataIndex].name;

    dataList[showDetailDataIndex].dailyWorkStatusList.forEach((dailyWorkStatus: Model.DailyWorkStatusRow) => {
      if ("" !== dailyWorkStatus.date) {
        showDailyWorkStatusList.push(dailyWorkStatus);
      }
    })

    showDays = dataList[showDetailDataIndex].days;
    showTotalWorkingTime = dataList[showDetailDataIndex].totalWorkingTime;

  }

  /**
   * 日毎の労働時間の作成
   * @param text 
   * @param row 
   * @param index 
   */
  const getWorkingTime = (text: string, row: Model.DailyWorkStatusRow, index: number) => {
    // 労働時間の取得
    let workingTime = formatUtils.formatTime(row.workingTime);
    const isValid = isValidPeriod(row.meetQualificationRequirementType);
    const isTimeEmptyOrZero = (time: string) => {
      return time === "" || time === "0:00" || time === "00:00";
    }

    let className = "";
    if (isValid) {
      if (row.editWorkingTimeFlag) {
        // 労働時間が編集されている
        className = styles.diffWorking;
      } else {
        className = styles.working;
      }
    } else {
      className = styles.working;
    }

    if (isTimeEmptyOrZero(workingTime)
      && (Utils.isNullOrEmpty(row.firstInTime)
      || Utils.isNullOrEmpty(row.lastOutTime)
      || Utils.isInversionInOutTime(row.firstInTime, row.lastOutTime))) {
      // (入退場の少なくとも一方が空 または 入退場時間が逆転している) かつ 労働時間がゼロならば空にする
      workingTime = "";
    }

    let workingTimeTag: any = "";
    if (isValid) {
      if (isNotSubmitted(dataList[showDetailDataIndex])) {
        // 未提出の場合は編集可能
        workingTimeTag = <a onClick={() => onClickEdit(dataList[showDetailDataIndex], index)}>{workingTime === "" ? "編集" : workingTime}</a>;
      } else {
        // 提出済みの場合は編集不可
        workingTimeTag = workingTime === "" ? <>&nbsp;</> : workingTime;
      }
    } else {
      // 有効期間外であれば「－」を表示
      workingTimeTag = '－'
    }

    return {
      children: <div className={styles.contentCenter}>{workingTimeTag}</div>,
      props: {
        className: className,
      }
    }
  }

  /**
 * 日毎の入場時刻の作成
 * @param text 
 * @param row 
 * @param index 
 */
  const getFirstInTime = (text: string, row: Model.DailyWorkStatusRow, index: number) => {
    const isValid = isValidPeriod(row.meetQualificationRequirementType);
    const isInversionInOutTime = Utils.isInversionInOutTime(row.firstInTime, row.lastOutTime);
    let firstInTime;
    if (isValid) {
      firstInTime = formatUtils.formatDateToTime(row.firstInTime);
    } else {
      firstInTime = "－";
    }
    return <div className={isInversionInOutTime ? styles.invalidInOutTime : styles.contentCenter}>{firstInTime}</div>
  }

  /**
  * 日毎の退場時刻の作成
  * @param text 
  * @param row 
  * @param index 
  */
  const getLastOutTime = (text: string, row: Model.DailyWorkStatusRow, index: number) => {
    const isValid = isValidPeriod(row.meetQualificationRequirementType);
    const isInversionInOutTime = Utils.isInversionInOutTime(row.firstInTime, row.lastOutTime);
    let lastOutTime;
    if (isValid) {
      lastOutTime = formatUtils.formatDateToTime(row.lastOutTime);
    } else {
      lastOutTime = "－";
    }
    return <div className={isInversionInOutTime ? styles.invalidInOutTime : styles.contentCenter}>{lastOutTime}</div>
  }

  // 労働者出勤実績タイトルセル
  const workerAttendanceRecordTitle = (text: string, row: Model.DailyWorkStatusRow, index: number) => {
    let rowSpan = 0;
    let titleStr = null;
    if (index === 0) {
      rowSpan = getLastDay(criteria.attendanceYearMonth);
      titleStr = <div className={styles.verticalTitle}>労働者出勤実績</div>
    }

    return {
      children: titleStr,
      props: {
        rowSpan: rowSpan,
        className: styles.titleBackgound
      }
    }
  }


  /** 曜日取得 */
  const getWeekDay = (text: string, row: Model.DailyWorkStatusRow) => {
    // 曜日文字列定義
    const weekDayChars = ['日', '月', '火', '水', '木', '金', '土'];
    let yearMonthDay = row.date.split("-");
    let year = parseInt(yearMonthDay[0]);
    let month = parseInt(yearMonthDay[1]);
    let day = parseInt(yearMonthDay[2]);
    let date = new Date(year, month - 1, day);

    return <span>{weekDayChars[date.getDay()]}</span>;

  }

  /** 有効期間内かどうか */
  const isValidPeriod = (type: string) => {
    return type !== CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code;
  }

  /**
   * CriteriaをStoreに保持
   * @param keyValueList 
   */
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordDetailCriteria = {
      attendanceYearMonth: criteria.attendanceYearMonth,
      primeCertificationNo: criteria.primeCertificationNo,
      workerLastName: criteria.workerLastName,
      workerFirstName: criteria.workerFirstName,
      workerLastNameKana: criteria.workerLastNameKana,
      workerFirstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      offset: criteria.offset,
      limit: criteria.limit,
      approved: criteria.approved,
      submitted: criteria.submitted,
      remand: criteria.remand,
      noSubmitted: criteria.noSubmitted
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setCriteria(newCriteria));
  }

  if (window.location.search !== ""
    && criteria.attendanceYearMonth === ""
    && projectId === ""
    && belongCompanyId === "") {
    let urlParamStr = window.location.search.substring(1);

    let workAttendanceYearMonth = "";
    let workProjectId = "";
    let workBelongCompanyId = "";

    urlParamStr.split('&').forEach(param => {
      const temp = param.split('=');

      if (temp[0] === "attendanceYearMonth") {
        workAttendanceYearMonth = temp[1];
      }
      if (temp[0] === "projectId") {
        workProjectId = temp[1];
      }
      if (temp[0] === "belongCompanyId") {
        workBelongCompanyId = temp[1];
      }
    });

    if (Utils.isNullOrEmpty(workAttendanceYearMonth) || Utils.isNullOrEmpty(workProjectId) || Utils.isNullOrEmpty(workBelongCompanyId)) {
      // 1つでも設定されていないパラメータが存在する場合
      history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY);
    }

    let urlParamValues: Model.UrlParameter = {
      attendanceYearMonth: workAttendanceYearMonth
      , projectId: workProjectId
      , belongCompanyId: workBelongCompanyId
    }

    dispatch(Module.setUrlParameterValue(urlParamValues));
  } else if (window.location.search === ""
    && criteria.attendanceYearMonth === ""
    && projectId === ""
    && belongCompanyId === "") {
    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY);
  }

  if ("" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_DETAIL === apiResult.url
    && editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE) {
    if (isNotSubmitted(dataList[showDetailDataIndex])) {
      // 編集不可の場合
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_DEADLINE_PASSED_PARTNER")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
  }

  /** 一覧部 */
  let columns = [
    {
      title: '項目',
      align: 'center' as 'center',
      children: [
        {
          colSpan: 0,
          align: 'center' as 'center',
          className: styles.workerAttendanceRecordTitle,
          render: workerAttendanceRecordTitle,
        },
        {
          title: '日数',
          colSpan: 2,
          align: 'center' as 'center',
          className: styles.tableCell,
          render: (text: string, row: Model.DailyWorkStatusRow) => {
            return <span>{parseInt(row.date.substr(-2))}</span>;
          },
        },
        {
          title: '合計',
          align: 'center' as 'center',
          className: styles.tableCell,
          render: getWeekDay,
        }
      ]
    },
    {
      title: '入退場履歴',
      align: 'center' as 'center',
      children: [
        {
          title: <div>{showDays} 日</div>,
          colSpan: 2,
          align: 'right' as 'right',
          className: styles.totalDays,
          render: getFirstInTime,
        },
        {
          colSpan: 0,
          render: getLastOutTime,
        }
      ]
    },
    {
      title: '労働時間',
      align: 'center' as 'center',
      children: [{
        title: <div>{showTotalWorkingTime}</div>,
        align: 'right' as 'right',
        className: styles.totalWorking,
        render: getWorkingTime,
      }]
    }
  ];

  const callGetDetailDataListApi = () => {
    // パラメータが存在しない場合はデータを取得しない
    if (criteria.attendanceYearMonth === ""
      || projectId === ""
      || belongCompanyId === "") {
      return;
    }

    let statusList: Array<string> = [];
    if (criteria.approved) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_APPROVED);
    }
    if (criteria.submitted) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_CONFIRMED);
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED);
    }
    if (criteria.remand) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_REMAND);
    }
    if (criteria.noSubmitted) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED);
    }
    let attendanceParams: Model.getDetailDataList.Request = {
      attendanceYearMonth: criteria.attendanceYearMonth,
      projectId: projectId,
      belongCompanyId: belongCompanyId,
      firstPartnerCompanyId: firstPartnerCompanyId,
      primeCertificationNo: criteria.primeCertificationNo,
      lastName: criteria.workerLastName,
      firstName: criteria.workerFirstName,
      lastNameKana: criteria.workerLastNameKana,
      firstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      status: statusList.toString(),
      offset: criteria.offset,
      limit: criteria.limit
    }

    let qualificationParams: Model.getOriginalQualificationList.Request = {
      primeCompanyId: primeCompanyId,
      belongCompanyId: belongCompanyId,
      workerId: "0", // workerIdは後で設定するので、ここでは"0"を設定
      yearMonth: criteria.attendanceYearMonth,
    }

    dispatch(Module.getDetailDataListAsync(attendanceParams, qualificationParams));
  }

  if (CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_DAILY === apiResult.url) {

    dispatch(Module.setLoading(false));

    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
      && "" === apiResult.errorCode) {
      const setMessage: message = {
        message: messagesUtils.getMessage("SUCCESS_ATTENDANCE_PROCESS", ["労働時間の編集"])
        , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
      }
      dispatch(setMessages([setMessage]));

      // 労働時間編集ダイアログを閉じる
      dispatch(Module.reflectModalState(false));

      dispatch(initApiResult());

      callGetDetailDataListApi();
    }

  } else if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_CMN_STATUS === apiResult.url) {

      const messageText = messagesUtils.getMessageTextFromSession();
      const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
      if (messageText !== null) {
        const setMessage: message = {
          message: messageText
          , messageType: messageType
        }
      dispatch(setMessages([setMessage]));

      messagesUtils.clearSessionMessage();

      // 提出および提出取消処理完了の旨を伝えるダイアログを表示
      window.alert(messageText);
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY);
  }

  /**
   * 戻るボタン押下
   */
  const onClickBack = () => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY);
  }

  /**
   * 各種処理ボタン押下
   * @param processType 処理種別 
   */
  const onClickProcess = (processType: CONSTANTS.ATTENDANCE_PROCESS_TYPE) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    
    // 処理タイプ毎にメッセージを取得
    let args = [];
    switch (processType) {
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
        args.push("提出");
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
        args.push("提出取消");
        break;
      default:
        return;
    }

    // 確認ダイアログの表示
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_ATTENDANCE_PROCESS", [args[0]]))) {
      return;
    }


    // 勤怠提出の場合に「入退場時間のどちらかがあるのに労働時間が0時間」となっている勤怠がある場合は確認ダイアログを出す
    if (processType === CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT) {
      const isTimeZero = (time: string) => {
        return time === "0:00" || time === "00:00" || time === "";
      }
      let alertDayList: number[] = [];
      const dailyWorkStatusList = dataList[showDetailDataIndex].dailyWorkStatusList;
      for (let i = 0; i < dailyWorkStatusList.length; i++) {
        const ws = dailyWorkStatusList[i];
        if ((!Utils.isNullOrEmpty(ws.firstInTime) || !Utils.isNullOrEmpty(ws.lastOutTime)) && isTimeZero(ws.workingTime)) {
          alertDayList.push(i + 1);
        }
      }

      if (alertDayList.length > 0) {
        let confirmTxt = "以下、労働時間が0時間と入力されている日付です。\r\nこのまま";
        confirmTxt += `${parseInt(criteria.attendanceYearMonth.substring(0, 4))}年${parseInt(criteria.attendanceYearMonth.substring(4, 6))}月分の勤怠を提出してもよろしいですか。\r\n`;
        let warningTextDate: string = alertDayList.map(d => `${d}日`).join("・");
        dispatch(Module.setWorktimeConfirmText(confirmTxt));
        dispatch(Module.setWorktimeConfirmTextDate(warningTextDate));
        dispatch(Module.reflectWorktimeConfirmModalVisibleState(true));
        return ;
      }
    }

    submitAttendance(processType);
  }

  const submitAttendance = (processType: CONSTANTS.ATTENDANCE_PROCESS_TYPE) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());

    let args = [];
    switch (processType) {
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
        args.push("提出");
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
        args.push("提出取消");
        break;
      default:
        return;
    }

    // リクエストデータの作成
    let destList: Array<Model.putStatus.Dest> = [];

    if (dataList[showDetailDataIndex].approvalStatus !== CONSTANTS.ATTENDANCE_STATUS_APPROVED) {
      let dest: Model.putStatus.Dest = {
        workRecordSubmissionHistoryId: dataList[showDetailDataIndex].workRecordSubmissionHistoryId,
        attendanceYearMonth: criteria.attendanceYearMonth,
        belongCompanyId: belongCompanyId,
        workerId: dataList[showDetailDataIndex].workerId,
        projectId: projectId,
        firstCompanyId: dataList[showDetailDataIndex].firstPartnerCompanyId,
        updateDatetime: dataList[showDetailDataIndex].updateDatetime
      }
      destList.push(dest);
    };

    // メッセージをセッションストレージに保管
    let message = messagesUtils.getMessage("SUCCESS_ATTENDANCE_PROCESS", [args[0]]);
    messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_SUCCESS);

    let putData: Model.putStatus.Request = {
      processType: processType,
      destList: destList
    }

    dispatch(Module.putAttendanceStatusAsync(putData));
  }

  /**
   * 表示勤怠情報の切替
   * @param value 
   */
  const changeDataIndex = (value: number) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "limit", value: 1 }
      , { key: "offset", value: criteria.offset + value }
    ])
  }

  /**
   * 編集/労働時間リンク押下時
   * @param row 
   * @param index 
   */
  const onClickEdit = (row: Model.WorkRecordDetailRow, index: number) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 労働時間をフォームに設定
    form.setFieldsValue({ workingTime: row.dailyWorkStatusList[index].workingTime });

    // 選択された勤怠年月日の情報をstateに保存
    let attendanceInfo: Model.SelectedAttendanceInfo = {
      workRecordSubmissionHistoryId: row.workRecordSubmissionHistoryId
      , primeCompanyId: primeCompanyId
      , belongCompanyId: belongCompanyId
      , workerId: row.workerId
      , projectId: projectId
      , firstPartnerCompanyId: row.firstPartnerCompanyId
      , dailyWorkStatus: row.dailyWorkStatusList[index]
    }
    dispatch(Module.setModalState(attendanceInfo));
    // 労働時間編集ダイアログの表示
    dispatch(Module.reflectModalState(true));
  }

  /**
   * 労働時間入力テキストボックスのフォーカスアウト時
   * @param value 
   */
  const onBlurWorkingTime = (value: string) => {
    let regexNumOnly12 = RegExp('^([0-9]{1,2})$');
    let regexNumOnly34 = RegExp('^([0-9]{1,2})([0-9]{2})$');
    let regex1colon = RegExp('^([0-9]{1,2}):([0-9]{1,2})$');

    let formatVal = "";
    if (regexNumOnly12.test(value)) {
      let mch = regexNumOnly12.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":00";
      }
    } else if (regexNumOnly34.test(value)) {
      let mch = regexNumOnly34.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":" + mch[2];
      }
    } else if (regex1colon.test(value)) {
      let mch = regex1colon.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":" + ("0" + mch[2]).slice(-2);
      }
    }

    form.setFieldsValue({ workingTime: formatVal });
  }

  /**
   * Enterキー押下時
   */
  const onPressEnter = () => {
    let value = form.getFieldValue('workingTime');
    onBlurWorkingTime(value);
  }

  /**
   * 確定ボタン押下
   */
  const onClickDecision = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 入力された労働時間の正当性のチェック
    let value = form.getFieldValue('workingTime');

    let regexTime = RegExp("^(2[0-3]|[0-1][0-9]):[0-5][0-9]$");

    if (value !== "" && !regexTime.test(value)) {
      // 入力された労働時間が時間として正しい値ではない
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_INVALID_WORKING_TIME")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setModalMessages([setMessage]));
      return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // リクエストデータの作成
    let putData: Model.editWorkingTime.Request = {
      workRecordSubmissionHistoryId: info.workRecordSubmissionHistoryId
      , primeCompanyId: info.primeCompanyId
      , belongCompanyId: info.belongCompanyId
      , workerId: info.workerId
      , projectId: info.projectId
      , firstPartnerCompanyId: info.firstPartnerCompanyId
      , yearMonth: formatUtils.formatDateToYearMonth(info.dailyWorkStatus.date)
      , day: formatUtils.formatDateToDay(info.dailyWorkStatus.date)
      , workingTime: value === "" ? null : value
      , updateDatetime: info.dailyWorkStatus.updateDatetime
    }
    dispatch(Module.setLoading(true));
    dispatch(Module.putEditAttendanceAsync(putData));
  }

  /**
   * キャンセルボタン押下
   */
  const onClickCancel = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: apiResult.status, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 労働時間編集ダイアログを閉じる
    dispatch(Module.reflectModalState(false));
  }

  /**
   * 労働時間の確認ダイアログのキャンセルボタン押下
   */
   const onClickWorktimeConfirmCancel = () => {
    dispatch(Module.reflectWorktimeConfirmModalVisibleState(false));
  }

  /**
   * 労働時間の確認ダイアログの提出ボタン押下
   */
  const onClickWorktimeConfirmSubmit = () => {
    dispatch(Module.reflectWorktimeConfirmModalVisibleState(false));
    submitAttendance(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT);
  }

  /** メッセージ部 */
  const Message = () => {
    if (modalVisibleState) {
      return <></>
    } else {
      return <CommonMessage searchNoDataName="作業員" />
    }
  }

  /** 一覧ヘッダー部 */
  const AttendanceDetailHeader = () => {
    return (
      <div style={{ whiteSpace: "nowrap" }} className={styles.searchGroup}>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle}>勤怠年月：</div>
          <div>
            {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>状態：</div>
          <div>
            {showDataStatus}
            {abolishCompanyButton(dataList[showDetailDataIndex])}
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>氏名：</div>
          <div>{showDataName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>元請会社：</div>
          <div>{primeCompanyName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>保持資格：</div>
          <div style={{ textAlign: "left" }}>{qualificationNameList(dataList[showDetailDataIndex])}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>作業所：</div>
          <div>{projectName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>一次協力会社：</div>
          <div style={{ textAlign: "left" }}>{firstPartnerCompanyName(dataList[showDetailDataIndex])}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>所属企業：</div>
          <div>{belongCompanyName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>作業次数：</div>
          <div style={{ textAlign: "left" }}>{topHierarchyLevel(dataList[showDetailDataIndex])}</div>
        </div>
      </div>
    )
  }

  /** ページ切替ボタン部 */
  const PaginationButton = () => {
    return (
      <>
        <AuthButton
          name={"＜"}
          size={"large"}
          onClick={() => changeDataIndex(-1)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={criteria.offset <= 0 ? true : false}
          style={{ margin: "10px" }}
        />

        <AuthButton
          name={"＞"}
          size={"large"}
          onClick={() => changeDataIndex(1)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={criteria.offset >= total - 1 ? true : false}
          style={{ margin: "10px" }}
        />
        <div>前の作業員　次の作業員</div>
      </>
    );
  }

  /** フッターボタン部 */
  const AttendanceButton = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          onClick={onClickBack}
          className={AuthButtonStyles.backButtonFixed}
          style={{ margin: "10px" }}
        />
        <AuthButton
          name={"提出"}
          authorities={[
            AuthButtonAuthorities.partnerAttendanceSubmitAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={total >= 1 && editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE && isNotSubmitted(dataList[showDetailDataIndex])
            ? false : true}
          style={{ margin: "10px" }}
        />

        <AuthButton
          name={"提出取消"}
          authorities={[
            AuthButtonAuthorities.partnerAttendanceSubmitAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={total >= 1 && editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE &&
            (isStatusConfirmed(dataList[showDetailDataIndex]) || isStatusUnApproved(dataList[showDetailDataIndex]))
            ? false : true}
          style={{ margin: "10px" }}
        />
      </FooterButtonArea>
    );
  }

  /** 確認ダイアログの文言 */
  const WorktimeConfirmText = () => {
    return (
      <>
        { worktimeConfirmText }
        <span className={styles.worktimeConfirmTextDate}>{ worktimeConfirmTextDate }</span>
      </>
    )
  }

  return (
    <Layout>
      <Header />
      <Content className={styles.content}>
        <Title level={4} className={styles.partnerfunctionTitle}>
          {props.title}
        </Title>
        <Message />
        <div style={{ textAlign: "left", fontSize: "20px", color: "red" }}>
          {isContainInvalidInOutTime ? "入場時間より退場時間が早い作業日があるため、通門設定に問題がないかを元請会社様にお問い合わせください。": ""}
        </div>
        <AttendanceDetailHeader />
        {0 < total ?
          <>
            {editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE && !isNotSubmitted(dataList[showDetailDataIndex]) ?
              <>
                <div className={styles.contentLeft}>提出済の勤怠情報の操作が必要な場合は、対象となる勤怠情報を提出した元請会社のご担当者にご連絡ください。</div>
              </>
              : <></>}
            <div className={styles.contentLeft}>・グリーンサイト通門管理機能上で入退場情報登録を申請した場合、元請会社が承認してからスキルマップサイトにデータが反映されるまで、しばらく時間かかることがあります。</div>
            <div className={styles.contentLeft}>・「一次協力会社」の下で異なる複数の編成（次数）に入っている場合、「作業次数」にはもっとも上位の次数が表示されます。</div>
            {!loginUserInfo.calcWorkTimeNotUsePrimeCompanyIdList.includes(primeCompanyId) ?
            <>
              <div className={styles.contentLeft}>・入退場いずれかの記録のみの作業日には「労働時間」欄に８時間が自動入力されています。変更する場合は、「労働時間」欄をクリックし、変更内容を登録してください。</div>
            </>
            : <></>}
            <div className={styles.contentLeft}>－...該当の日付に有効な資格が1つも存在しない</div>
            <div className={styles.contentLeft}>
              {abolishCompanyAnnotations()}
            </div>          
            <PaginationButton />
            <Table
              size="small"
              className="hoverNotBackground"
              columns={columns}
              dataSource={showDailyWorkStatusList}
              pagination={false}
              bordered
            >
            </Table>
            <PaginationButton />
          </> : <></>
        }
      </Content>
      <AttendanceButton />
      <Footer />

      <Modal
        visible={modalVisibleState}
        footer={[
          <AuthButton
            name={"キャンセル"}
            size={"large"}
            shape={"round"}
            onClick={onClickCancel}
            className={AuthButtonStyles.backButtonFixed}
            style={{ margin: "10px" }}
          />,
          <AuthButton
            name={"確定"}
            size={"large"}
            shape={"round"}
            onClick={onClickDecision}
            className={AuthButtonStyles.partnerAuthButtonFixed}
            loading={isLoading}
            style={{ margin: "10px" }} />
        ]}
        destroyOnClose
        closable={false}
        width={350}>
        <Layout>
          <CommonMessage isModal={true} />
          <Content
            style={{ margin: "auto", textAlign: "right" }}
            hasapicall={"false"}>
            <Form
              form={form}
              initialValues={{
                "workingTime": info.dailyWorkStatus.workingTime
              }}
            >
              <Form.Item label="日付">
                {formatUtils.formatDateToYearsAndDayWeek(info.dailyWorkStatus.date)}
              </Form.Item>
              <Form.Item label="入場時間">
                {formatUtils.formatDateToTime(info.dailyWorkStatus.firstInTime)}
              </Form.Item>
              <Form.Item label="退場時間">
                {formatUtils.formatDateToTime(info.dailyWorkStatus.lastOutTime)}
              </Form.Item>
              <Form.Item name="workingTime" label="労働時間">
                <Input
                  type="text"
                  onBlur={(e) => onBlurWorkingTime(e.target.value)}
                  onKeyPress={((e) => {
                    if (e.key === "Enter") {
                      onPressEnter();
                      e.preventDefault()
                    }
                  })}
                  style={{ width: "100px", textAlign: "right" }}
                  maxLength={CONSTANTS.WORKING_TIME_MAX_LENGTH}
                  autoFocus
                />
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </Modal>

      <Modal
        visible={worktimeConfirmModalVisibleState}
        footer={[
          <AuthButton
            name={"労働時間の修正に戻る"}
            size={"large"}
            shape={"round"}
            onClick={onClickWorktimeConfirmCancel}
            className={AuthButtonStyles.backButtonFixed}
            style={{ padding: "6px", margin: "0 3px", width: "11em" }}
          />,
          <AuthButton
            name={"このまま提出する"}
            size={"large"}
            shape={"round"}
            onClick={onClickWorktimeConfirmSubmit}
            className={AuthButtonStyles.partnerAuthButtonFixed}
            style={{ padding: "6px", margin: "0 3px", width: "11em" }} />
        ]}
        destroyOnClose
        closable={false}>
        <Layout>
          <Content
            style={{
              margin: "auto",
              whiteSpace: "pre-wrap",
              fontSize: "30px",
            }}>
            <WorktimeConfirmText />
          </Content>
        </Layout>
      </Modal>
    </Layout>
  );
}


